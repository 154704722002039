import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router'
import Helmet from 'react-helmet'
import { getSectionLabel, url2section } from 'ion-sections'
import qs from 'qs'

import DefaultSectionPage from '../pages/DefaultSectionPage'
import Article from '../pages/Article'
import NotFound from '../pages/NotFound'
import AuthorSectionPage from '../pages/AuthorSectionPage'

const DefaultSection = (props) => {
  const params = props.location ? qs.parse(props.location.search, { ignoreQueryPrefix: true }) : { page: 0 }
  const isPage0 = (params.page || 0) < 2

  return (
    <>
      <Helmet titleTemplate={props.titleTemplate} />
      <Switch>
        <Route path={props.match.url + '/:section([a-zA-Z0-9-]+)/'} strict exact render={p => <Redirect to={props.match.url + '/' + p.match.params.section} />} />
        {props.match.url === props.location.pathname && (
          <Switch>
            <Route
              path='/izindaba' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='izindaba'
                  title='Izindaba | Isolezwe'
                  description='Izindaba ezishisayo, ezihlabahlosile nezingachemile zakamuva ezithinta ipolitiki, izigameko ezingajewayelekile, ubugebengu, ezithinta umphakathi jikelele, ezempilo, ezemfundo.'
                />}
            />
            <Route
              path='/ezokungcebeleka' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='ezokungcebeleka'
                  title='Ezokungcebeleka | Isolezwe'
                  description='Thola ezishisayo ngosaziwayo nonontandakubukwa baseNingizimu Afrika nabakwamanye amazwe omhlaba, funda ngezenzeka emisakazweni, kwezeshashalazi nemicimbi eyehlukahlukene.'
                />}
            />
            <Route
              path='/ezokungcebeleka/ezemisakazo' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='ezokungcebeleka/ezemisakazo'
                  title='Ezemisakazo | Ezokungcebeleka | Isolezwe'
                  description='Izindaba ezithinta ezemisakazo, sixoxa nabasakazi ngempilo yabo, nothando lwabo lwezokusakaza.'
                />}
            />
            <Route
              path='/ezokungcebeleka/ezethelevishini' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='ezokungcebeleka/ezethelevishini'
                  title='Ezethelevishini | Ezokungcebeleka | Isolezwe'
                  description='Imidlalo ehlukahlukene yethelevishini, impilo yabalingisi nababhali bemidlalo, ezamafilimu nezinye izinhlelo.'
                />}
            />
            <Route
              path='/ezokungcebeleka/osaziwayo' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='ezokungcebeleka/osaziwayo'
                  title='Osaziwayo | Ezokungcebeleka | Isolezwe'
                  description='Thola ezishisayo ngosaziwayo nonontandakubukwa baseNingizmu Afrika, izingxoxo ezikhethekile nosaziwayo, umgosi ovuthayo noQekethwayo.'
                />}
            />
            <Route
              path='/ezokungcebeleka/umculo' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='ezokungcebeleka/umculo'
                  title='Umculo | Ezokungcebeleka | Isolezwe'
                  description='Izindaba ezithinta abaculi, umculo omusha, ukubuyekezwa kwama-albhamu amasha abaculi, amakhinsathi namafestiveli neminye imicimbi.'
                />}
            />
            <Route
              path='/ezemidlalo' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='ezemidlalo'
                  title='Ezemidlalo | Isolezwe'
                  description='Zithole zishisa ezemidlalo; ezebhola, abasubathi, ikhilikithi, ibhola lombhoxo, ibhola lomphebezo nezinye izinhlobo zemidlalo.'
                />}
            />
            <Route
              path='/ezemidlalo/ibhola' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='ezemidlalo/ibhola'
                  title='iBhola | Ezemidlalo | Isolezwe'
                  description='Thola ezishisayo ngamaqembu akuleli  ePSL nawaphesheya kwezilwandle, okwenzeka kubadlali abasezingeni lomhlaba, imibiko yemidlalo, imiphumela ne-log.'
                />}
            />
            <Route
              path='/ezemidlalo/ibhola/upotshoza' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='ezemidlalo/ibhola/upotshoza'
                  title='uPotshoza | iBhola | Ezemidlalo | Isolezwe'
                  description='Uthole ushisa umgosi wokwenzeka emaqenjini ePSL, abadlali nabaqeqeshi, osihlalo bamaqembu, ukudayiswa nokubolekiswa kwabadlali nezinkontileka ezintsha zabadlali.'
                />}
            />
            <Route
              path='/ezemidlalo/isigcawu-sabalandeli' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='ezemidlalo/isigcawu-sabalandeli'
                  title='Isigcawu sabalandeli | Ezemidlalo | Isolezwe'
                  description='Imibono yabalandeli bebhola kuleli baphawula ngokugculisela nokungagculiseki ngesimo samaqembu, esabaqeqeshi, esabadlali, izinqumo ezithathwa ngonompempe nosihlalo bamaqembu.'
                />}
            />
            <Route
              path='/ezemidlalo/ezinye-ezemidlalo' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='ezemidlalo/ezinye-ezemidlalo'
                  title='Ezinye ezemidlalo | Ezemidlalo | Isolezwe'
                  description='Thola izindaba ezithinta ezinye ezemidlalo kuleli naphesheya kwezilwandle; ezabasubathi, ikhilikithi, ibhola lombhoxo, ibhola lomphebezo nezinye izinhlobo zemidlalo.'
                />}
            />
            <Route
              path='/imibono' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='imibono'
                  title='Imibono | Isolezwe'
                  description='Zizwele ukuthi abafundi beSolezwe bathini ngezindaba ezisematheni, izigemegeme ezibikiwe, isimo sezwe, nezimvo zabo.'
                />}
            />
            <Route
              path='/ezokuvakasha' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='ezokuvakasha'
                  title='Ezokuvakasha | Ezokuvakasha | Isolezwe'
                  description=''
                />}
            />
            <Route
              path='/ezokuvakasha/ezemfashini-nezokuzicwala' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='ezokuvakasha/ezemfashini-nezokuzicwala'
                  title='Ezemfashini nezokuzicwala | Ezokuvakasha | Isolezwe'
                  description='Konke odinga ukukwazi ngokuzicwala nemfashini yakamuva, ongoti bacobelela ngamathipha ezemfashini nezitayela ezingenisile.'
                />}
            />
            <Route
              path='/ezokuvakasha/phola-ekzn' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='ezokuvakasha/phola-ekzn'
                  title='Phola eKZN | Ezokuvakasha | Isolezwe'
                  description=''
                />}
            />
            <Route
              path='/ezokuvakasha/izithombe' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='ezokuvakasha/izithombe'
                  title='Izithombe | Ezokuvakasha | Isolezwe'
                  description=''
                />}
            />
            <Route
              path='/impilo-yabantu' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='impilo-yabantu'
                  title='Impilo yabantu | Isolezwe'
                  description='Benzani abantu ezimpilweni zabo zangasese, ezothando, ezocansi nezempilo, thola amathiphu angakusiza kwezothando nengosi yezocansi.'
                />}
            />
            <Route
              path='/impilo-yabantu/intandokazi' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='impilo-yabantu/intandokazi'
                  title='Intandokazi | Isolezwe'
                  description='Siqhakambisa imisebenzi eyenziwa ngabesifazane, abadala nabancane bawo wonke amazinga empilo ngenhloso yokugqugquzela, ukuthuthukisa nokuqhakambisa abesifazane.'
                />}
            />
            <Route
              path='/impilo-yabantu/ezothando' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='impilo-yabantu/ezothando'
                  title='Ezothando | Impilo yabantu | Isolezwe'
                  description='Thola amathiphu angakusiza kwezothando nengosi yezocansi, siphendula imibuzo onayo ngezothando kanye nokunye okungasiza kuqinise ubudlelwano bakho.'
                />}
            />
            <Route
              path='/impilo-yabantu/ezempilo' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='impilo-yabantu/ezempilo'
                  title='Ezempilo | Impilo yabantu | Isolezwe'
                  description='Izindaba ezibalulekile ezithinta ezempilo, ulwazi ngezifo kanye nokudla okunempilo okungakusiza ugweme izifo.'
                />}
            />
            <Route
              path='/impilo-yabantu/ezezimoto' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='impilo-yabantu/ezezimoto'
                  title='Ezezimoto | Impilo yabantu | Isolezwe'
                  description='Sikulethela ezakamuva ezithinta imboni yezezimoto, izimoto ezintsha nezenziwe kabusha, ubuchwepheshe bakamuva nengosi Umuntu Nemoto Yakhe.'
                />}
            />
            <Route
              path='/impilo-yabantu/whatsfordinner' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='whatsfordinner'
                  title='whatsfordinner | Impilo yabantu | Isolezwe'
                  description="Find easy and affordable recipes for any occasion and get cooking right away. We'll gather your personal taste and create a weekly menu tailored for your health. Whatsfordinner is a foodie's dream come true. Start cooking right away; there's something for everyone!"
                />}
            />
            <Route
              path='/:section([a-zA-Z0-9/-]+)' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  title={getSectionLabel(url2section(props.location.pathname)) + ' | Latest News & Developments | Isolezwe'}
                  section={url2section(props.location.pathname)} url={props.location.pathname}
                />}
            />
            <Route path='*' render={(p) => <NotFound {...props} {...p} />} />
          </Switch>
        )}
        <Route
          path='/authors/:section([a-zA-Z-]+)' render={(p) => <AuthorSectionPage
            {...props} {...p}
            isPage0={isPage0}
            section={'authors/' + p.match.params.section}
            url={props.location.pathname}
            authorSlug={p.match.params.section}
                                                              />}
        />
        <Route path={props.match.url + '/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} render={(p) => <Article {...props} {...p} contentKey={p.match.params.contentKey} />} />
        <Route path={props.match.url + '/:title*([-])*:contentKey([0-9]{5,})'} render={(p) => <Article {...props} {...p} contentKey={p.match.params.contentKey} />} />
        <Route path={props.match.url + '/:section([a-zA-Z0-9-]+)'} render={(p) => <DefaultSection {...props} {...p} />} />
      </Switch>
    </>
  )
}

export default DefaultSection
